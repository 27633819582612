import { Dialog } from "vant";

export default {
  name: "UserInfo",
  data() {
    return {
      userData: {},
    };
  },
  mounted() {
    const userObj = JSON.parse(localStorage.getItem("userObj"));
    this.findData(userObj.uuid);
    document.title = "个人信息";
  },
  methods: {
    findData(uid) {
      this.$axios.get(`/api/mobile/user/detail/${uid}`).then((res) => {
        if (res.data.code === 200) {
          console.log("res.data", res.data);
          this.userData = res.data.data;
        }
      });
    },
    backHome() {
      this.$router.push("/home");
    },
    doLogout() {
      Dialog.confirm({
        message: "确认退出吗？",
        confirmButtonColor: "#1a89fa",
      })
        .then(() => {
          // on confirm
          sessionStorage.removeItem("userObj");
          sessionStorage.removeItem("z-token");
          localStorage.clear();
          this.$router.push({
            path: "/",
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
